import React from 'react';

const PlainText = (props) => {
  const { label, value = '', onClick } = props;
  return (
    <div onClick={onClick} style={props.layout}>
      {label ? <strong>{label}</strong> : null}
      {value ? <p>{value.toString()}</p> : null}
    </div>
  );
};

export default PlainText;
