import React from 'react';
import PropTypes from 'prop-types';
import kebabCase from 'lodash/kebabCase';
import get from 'lodash/get';

const TextArea = (props) => {
  const { $id, disabled, schemaPath, onFocus, onChange, value, layout, styles } = props;
  return (
    <div style={layout}>
      <label htmlFor={kebabCase($id)}>{props.label} {get(props.validations, 'required.constraint', false) ? <sup>*required</sup> : ''}</label>
      <textarea
        style={styles}
        id={kebabCase($id)}
        key={$id}
        name={$id}
        data-schema-id={schemaPath}
        disabled={disabled}
        onFocus={onFocus}
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

TextArea.propTypes = {
  $id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  schemaPath: PropTypes.string.isRequired,
  value: PropTypes.any
};

TextArea.defaultProps = {
  disabled: false
};

export default TextArea;
