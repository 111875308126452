import React from 'react';

const LabelValue = (props) => {
  const { label, value = '' } = props;
  const handleClick = (e) => this.props.onClick(e);
  return (
    <div onClick={handleClick} style={props.layout}>
      {label ? <strong>{label}</strong> : null}{' '}
      {value ? <span>{value.toString()}</span> : null}
    </div>
  );
};

export default LabelValue;
