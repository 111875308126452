exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* COLORS */\n/* DISPLAY */\n.components-Form-RadioGroup-styles__root--NLVG8 {\n  align-items: flex-start;\n  display: flex;\n  justify-content: space-around; }\n  .components-Form-RadioGroup-styles__root--NLVG8 label {\n    flex: 0 1 auto; }\n\n.components-Form-RadioGroup-styles__horizontal--160uk {\n  flex-direction: row; }\n\n.components-Form-RadioGroup-styles__vertical--1W66u {\n  flex-direction: column; }\n", ""]);

// exports
exports.locals = {
	"root": "components-Form-RadioGroup-styles__root--NLVG8",
	"horizontal": "components-Form-RadioGroup-styles__horizontal--160uk",
	"vertical": "components-Form-RadioGroup-styles__vertical--1W66u"
};