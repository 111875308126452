import React, { PureComponent } from 'react';
import ObjectInspector from 'react-object-inspector';

import styles from './styles.scss';

export default class SchemaInfo extends PureComponent {
  render () {
    const { props } = this;
    const isVisible = Object.keys(props).length && !!props.component;
    const className = `${styles.devPopover} ${isVisible ? styles.active : ''}`;
    return (
      <div className={className}>
        <ObjectInspector
          data={props}
          name={props.component}
          initialExpandedPaths={['root', props.component]}
        />
      </div>
    );
  }
}
