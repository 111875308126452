exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* COLORS */\n/* DISPLAY */\n.components-Form-SmartButton-styles__root--2OrZU {\n  appearance: none;\n  border: none;\n  cursor: pointer;\n  font-size: 16px;\n  padding: 5px 10px;\n  background-color: #071D49;\n  color: #FFFFFF;\n  font-family: Calibri, \"Microsoft Sans Serif\";\n  transition: transform 150ms ease;\n  font-size: 16px;\n  text-transform: uppercase; }\n  .components-Form-SmartButton-styles__root--2OrZU:hover {\n    transform: scale(1.05); }\n", ""]);

// exports
exports.locals = {
	"root": "components-Form-SmartButton-styles__root--2OrZU"
};