exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* COLORS */\n/* DISPLAY */\n.components-Form-Page-styles__nav--2qP3s {\n  align-items: stretch;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-evenly;\n  background-color: #9B9B9B;\n  color: white;\n  text-decoration: none;\n  top: 0;\n  height: 50px;\n  box-shadow: none;\n  padding: 0 35px;\n  position: relative;\n  z-index: 100; }\n  .components-Form-Page-styles__nav--2qP3s a, .components-Form-Page-styles__nav--2qP3s a:active {\n    align-items: center;\n    background-color: #9B9B9B;\n    display: flex;\n    flex: 0 1 auto;\n    color: #FFFFFF;\n    justify-content: center;\n    text-decoration: none;\n    padding: 10px;\n    cursor: pointer; }\n  .components-Form-Page-styles__nav--2qP3s a:hover {\n    background-color: #5A5A5A; }\n\n.components-Form-Page-styles__root--9G8gj {\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  max-width: 1200px;\n  margin: 0px auto;\n  min-height: 100%; }\n", ""]);

// exports
exports.locals = {
	"nav": "components-Form-Page-styles__nav--2qP3s",
	"root": "components-Form-Page-styles__root--9G8gj"
};