import React from 'react';
import kebabCase from 'lodash/kebabCase';

import styles from './styles.scss';

const Radio = (props) => {
  const { $id, onChange, checked, label, value, disabled } = props;
  const id = kebabCase(`${$id}-${value}`);
  return (
    <label htmlFor={id} className={styles.root} style={props.layout}>
      <input
        id={id}
        name={$id}
        style={props.styles}
        onChangeCapture={onChange}
        disabled={disabled}
        type="radio"
        checked={checked || false}
        value={value}
      />
      {label}
    </label>
  );
};

export default Radio;
