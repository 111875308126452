/** @module actions/main */

import { createAction, handleActions } from 'redux-actions';
import some from 'lodash/some';
import mergeWith from 'lodash/mergeWith';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';
import has from 'lodash/has';
import omitBy from 'lodash/omitBy';
import get from 'lodash/get';
import set from 'lodash/set';
import isPlainObject from 'lodash/isPlainObject';
import merge from 'lodash/merge';
import update from 'lodash/update';
import { isParentVisible } from '../utils';

import {
  updateSchemaInstance,
  executeQuery,
  resolveSchemaState,
  updateValidations
} from '../utils/reducer';
// import { cloneSchema } from '../utils/index';
export { executeQuery } from '../utils/reducer';

/**
 * CSE's default internal configuration
 *
 * @constant {Object}
 * @prop {Object} index
 * @prop {String} index.key - The key within the provided `schema` that is considered unique (but not required) per-node
 * @prop {Boolean} index.inherit - Whether the `key`s should be inherited to build compound-paths
 * @prop {Function} get - A method to resolve values from the given data `model`
 * @prop {Function} set - A method used to set values on the data `model`
 */
const defaultConfig = {
  index: { key: 'id', inherit: false },
  get: ($id, model) => get(model, $id, null),
  set: ($id, model, value) =>
    update(model, $id, val => (isPlainObject(val) ? merge(val, value) : value))
};

/**
 * CSE's default state. These values are exposed on `window.store`
 *
 * @constant {Object}
 */
export const initialState = {
  lastUpdate: Date.now(),
  initialize: true,
  indexes: null,
  links: null,
  config: defaultConfig,
  activeElement: null,
  errors: null,
  updatedPaths: [],
  dependencies: {}
};

/**
 * @type {action}
 * Internally tells CSE to initialize the schema
 */
export const EMIT_INITIALIZE_SCHEMA = 'EMIT_INITIALIZE_SCHEMA';
/**
 * @type {action}
 * Internally tells CSE to initialize the schema with the model
 */
export const EMIT_INITIALIZE_MODEL = 'EMIT_INITIALIZE_MODEL';
/**
 * @type {action}
 * Internally tells CSE to update the entire state
 */
export const EMIT_UPDATE_STATE = 'EMIT_UPDATE_STATE';
/**
 * @type {action}
 * Internally tells CSE that the model was updated
 */
export const EMIT_UPDATE_MODEL = 'EMIT_UPDATE_MODEL';
/**
 * @type {action}
 * Internally tells CSE that the `data` prop was updated
 */
export const EMIT_UPDATE_DATA = 'EMIT_UPDATE_DATA';
/**
 * @type {action}
 * Internally tells CSE that an input was updated
 */
export const EMIT_INPUT_UPDATE = 'EMIT_INPUT_UPDATE';
/**
 * @type {action}
 * Internally tells CSE that the dependency graph was updated
 */
export const EMIT_DEPENDENCY_UPDATE = 'EMIT_DEPENDENCY_UPDATE';
/**
 * @type {action}
 * Internally tells CSE that a particular node within its state was updated
 */
export const EMIT_UPDATE_COMPONENT_STATE = 'EMIT_UPDATE_COMPONENT_STATE';
/**
 * @type {action}
 * Internally tells CSE that some component executed a given `trigger`
 */
export const EMIT_COMPONENT_TRIGGER = 'EMIT_COMPONENT_TRIGGER';
/**
 * @type {action}
 * Internally tells CSE to execute a specific `trigger`
 */
export const EMIT_EXECUTE_TRIGGER = 'EMIT_EXECUTE_TRIGGER';
/**
 * @type {action}
 * Internally tells CSE that the validations passed on `model`
 */
export const EMIT_VALIDATIONS_PASS = 'EMIT_VALIDATIONS_PASS';
/**
 * @type {action}
 * Internally tells CSE that the validations failed on `model`
 */
export const EMIT_VALIDATIONS_FAIL = 'EMIT_VALIDATIONS_FAIL';
/**
 * @type {action}
 * Internally tells CSE that validations have been resolved
 */
export const EMIT_VALIDATIONS_UPDATE = 'EMIT_VALIDATIONS_UPDATE';
/**
 * @type {action}
 * Internally tells CSE that a component focused itself (unused)
 */
export const EMIT_COMPONENT_FOCUS = 'EMIT_COMPONENT_FOCUS';
/**
 * @type {action}
 * Internally tells CSE that a group (ie. "repeatable-node") was added
 */
export const EMIT_INSTANCE_ADD = 'EMIT_INSTANCE_ADD';
/**
 * @type {action}
 * Internally tells CSE that a group (ie. "repeatable-node") was removed
 */
export const EMIT_INSTANCE_REMOVE = 'EMIT_INSTANCE_REMOVE';

const emitInitializeSchema = createAction(
  EMIT_INITIALIZE_SCHEMA,
  (schema, config, model = {}, data = {}) => ({
    schema,
    config,
    model,
    data
  })
);

const emitInitializeModel = ({ model, data, schema }) => (dispatch, getState, props) => {
  const state = getState();
  return dispatch(createAction(EMIT_INITIALIZE_MODEL)({
    model: cloneDeep(model),
    data: data || state.data || props.data,
    schema: schema ? cloneDeep(schema || props.schema) : state.schema
  }));
};

// TODO: COMPUTED STYLES ARE NOT CSE, AND CSE SHOULD NOT KNOW ABOUT THEM
const NON_THREAD_SAVE_PROPS = ['actions', 'onChange', 'triggers', 'children', 'computedStyles'];
const FILTER_THREAD_SAFE = (v, k) => NON_THREAD_SAVE_PROPS.includes(k) || typeof v === 'function';

const emitUpdateData = createAction(EMIT_UPDATE_DATA);
const emitUpdateModel = createAction(EMIT_UPDATE_MODEL);
const emitUpdateState = createAction(EMIT_UPDATE_STATE, updates => ({ ...updates }));
const emitInputUpdate = createAction(EMIT_INPUT_UPDATE, (element) => omitBy(element, FILTER_THREAD_SAFE));
const emitComponentUpdate = createAction(EMIT_UPDATE_COMPONENT_STATE, (element) => omitBy(element, FILTER_THREAD_SAFE));
const emitDependencyUpdate = createAction(EMIT_DEPENDENCY_UPDATE);

const emitInstanceAdd = createAction(EMIT_INSTANCE_ADD, (element) => omitBy(element, FILTER_THREAD_SAFE));
const emitComponentFocus = createAction(EMIT_COMPONENT_FOCUS);
const emitInstanceRemove = createAction(EMIT_INSTANCE_REMOVE);

const emitExecuteTrigger = createAction(EMIT_EXECUTE_TRIGGER, (method, args, result) => ({
  method,
  args,
  result
}));
/**
 * Attempts to execute a schema-specified `trigger`
 *
 * @function emitComponentTrigger
 *
 * @param  {String}    trigger      The trigger to be executed
 * @param  {Object}    context      The object invoking `trigger`
 * @param  {Object}    actions      A map of schema-level action-name => function
 * @param  {Any} defaultArgs)       Additional arguments from invocation
 */
const emitComponentTrigger = (trigger, context, actions, triggers, ...defaultArgs) => (
  dispatch,
  getState
) => {
  // Dynamic triggers have the ability to intercept actions and evaluate the
  // _potential_ state of that action before actually commiting it.
  const isQuery = isPlainObject(trigger);
  let state;
  let field;
  // Triggers run before any reducer is run, we update the state with `context`
  // to account for any intended updates during a trigger
  if (isQuery) {
    state = getState();
    // If this element looks at the generic model...
    context = { ...context, model: state.model };
    field = {
      ...state,
      schema: update(state.schema, context.schemaPath, previousSchema => ({
        ...previousSchema,
        ...omitBy(context, FILTER_THREAD_SAFE)
      }))
    };
  }

  const [method, ...args] = isQuery
    ? executeQuery(trigger, context, field) || []
    : [].concat(trigger);

  const func = typeof method === 'string'
    ? triggers[method] || actions[method]
    : method;

  // Only query triggers can potentially return a no-op
  if (typeof func !== 'function' && !isQuery) {
    throw new Error(`Trigger ${trigger} could not be resolved`);
  } else if (typeof func !== 'function') {
    // If it was a query no-op, then continue to update the field firing the trigger
    return dispatch(emitInputUpdate(omitBy(context, FILTER_THREAD_SAFE)));
  }

  return dispatch(
    emitExecuteTrigger(
      method,
      [...args, ...defaultArgs, context],
      func(...args, ...defaultArgs, context)
    )
  );
};

const emitValidationsPass = createAction(EMIT_VALIDATIONS_PASS, (schema, errors, ts) => ({
  schema,
  errors,
  ts
}));
const emitValidationsFail = createAction(EMIT_VALIDATIONS_FAIL, (schema, errors, ts) => ({
  schema,
  errors,
  ts
}));

/**
 * Runs validations on entire schema, triggers `VALIDATIONS_PASS` or `VALIDATIONS_FAIL`
 * pending schema status
 *
 * Also evaluates any `queryConstaint` defined within validations. These are queries
 * that return `true` for `VALIDATION_PASS` and `false` for `VALIDATION_FAIL`
 *
 * @function emitValidations
 * @param {Function} dispatch - A redux-like action dispatcher
 * @param {Function} getState - Get the current state during validation
 * @returns {Promise} A promise that resolves `true` for `VALIDATIONS_PASS` and `false` for `VALIDATIONS_FAIL`
 */
const emitValidations = subSchema => (dispatch, getState) => {
  const state = getState();
  const { indexes } = state;

  const schema = !(subSchema && 'schemaPath' in subSchema)
    ? state.schema
    : resolveSchemaState(
        omitBy(subSchema, FILTER_THREAD_SAFE),
        {
          ...state,
          schema: update(state.schema, subSchema.schemaPath, currentSchema => ({
            ...currentSchema,
            ...omitBy(subSchema, FILTER_THREAD_SAFE)
          }))
        },
        true // recursively update the component
      );

  const now = Date.now();
  // using the `indexes` (map of schemaPath => model path)
  const { schema: validatedSchema, formErrors, hasErrors } = Object.keys(indexes).reduce(
    ({ schema: validatedSchema, formErrors, hasErrors }, schemaPath) => {
      const relativeSchemaPath = schemaPath.replace(`${schema.schemaPath}.`, '');
      // If a `subSchema` is supplied, remove its path from schemaPaths
      const component =
        schema.schemaPath === schemaPath ? schema : get(schema, relativeSchemaPath, {});
      // Only validate elements with a value (and validations)
      component.visible = isParentVisible(component);

      const shouldValidate = component.validations && component.visible &&
        some(
          component.validations,
          validation => validation.constraint || has(validation, 'queryConstraint')
        );
      if (!shouldValidate) return { schema, formErrors, hasErrors };
      // Component validations (will either be empty object or have one key `errors`)
      const { errors: errors = [] } = updateValidations(
        // Any relative schema queries (ie. `schemaPath: './'`) need to have their schema-related
        // properties up-to-date, so we're resolving the latest state for the component.
        resolveSchemaState(
          { ...component, pristine: false },
          state,
          true
        ),
        state
      );
      if (!errors.length) return { schema, formErrors, hasErrors };
      return {
        schema:
          schema.schemaPath === schemaPath
            ? validatedSchema
            : update(validatedSchema, relativeSchemaPath, prevState => ({
                ...prevState,
                ...component,
                lastUpdate: now,
                pristine: false,
                update: true,
                errors
              })),
        hasErrors: hasErrors || (errors || []).length > 0,
        // don't add empty error lists
        formErrors:
          (errors || []).length > 0
            ? {
                ...formErrors,
                [schemaPath]: (formErrors[schemaPath] || []).concat(errors)
              }
            : formErrors
      };
    },
    { schema, hasErrors: false, formErrors: {} }
  );

  if (hasErrors) {
    dispatch(emitValidationsFail(validatedSchema, formErrors, now));
    return Promise.reject(emitValidationsFail(validatedSchema, formErrors));
  }

  dispatch(emitValidationsPass(validatedSchema, formErrors, now));
  return Promise.resolve(emitValidationsPass(validatedSchema, formErrors));
};

export const actions = {
  emitInitializeSchema,
  emitInitializeModel,
  emitComponentTrigger,
  emitInputUpdate,
  emitDependencyUpdate,
  emitComponentUpdate,
  emitInstanceAdd,
  emitInstanceRemove,
  emitComponentFocus,
  emitValidations,
  emitUpdateData,
  emitUpdateModel,
  emitUpdateState
};

/**
 * @default
 * @returns {Function} action handler for CSE instances. All actions are handled here and resolve CSE state transitions
 */
export default handleActions(
  {
    [EMIT_INITIALIZE_SCHEMA]: (state, { payload }) =>
      updateSchemaInstance({
        ...state,
        ...payload,
        initialize: true,
        updatedPaths: [],
        lastUpdate: Date.now(),
        indexes: {},
        links: {},
        errors: {},
        dependencies: {},
        config: {
          ...defaultConfig,
          ...payload.config,
          index: {
            ...defaultConfig.index,
            ...(payload.config || {}).index
          }
        }
      }),

    [EMIT_INITIALIZE_MODEL]: (state, { payload }) =>
      updateSchemaInstance({
        ...state,
        initialize: true,
        lastUpdate: Date.now(),
        ...payload
      }),

    [EMIT_DEPENDENCY_UPDATE]: (state, { payload }) =>
      updateSchemaInstance({
        ...state,
        initialize: true,
        lastUpdate: Date.now(),
        ...payload
      }),

    [EMIT_UPDATE_DATA]: (state, { payload }) =>
      updateSchemaInstance({
        ...state,
        initialize: true,
        lastUpdate: Date.now(),
        data: mergeWith(
          state.data,
          payload,
          (curVal, newVal) =>
            isObject(curVal) || isObject(newVal)
              ? isArray(newVal) ? newVal : merge(curVal, newVal)
              : newVal
        )
      }),

    [EMIT_UPDATE_MODEL]: (state, { payload }) =>
      updateSchemaInstance({
        ...state,
        initialize: true,
        errors: {},
        lastUpdate: Date.now(),
        model: mergeWith(
          state.model,
          payload,
          (curVal, newVal) =>
            isObject(curVal) || isObject(newVal)
              ? isArray(newVal) ? newVal : merge(curVal, newVal)
              : newVal
        )
      }),

    [EMIT_UPDATE_STATE]: (state, { payload }) => {
      const now = Date.now();
      const { dependencies } = state;

      const updatePaths = [].concat(dependencies.data || []).concat(dependencies.model || []);

      return {
        ...state,
        ...payload,
        updatedPaths: updatePaths,
        lastUpdate: now,
        initialize: false,
        schema: {
          ...updatePaths.reduce(
            (newSchema, path) =>
              update(newSchema, path, component => ({
                ...resolveSchemaState(
                  {
                    ...component,
                    data: payload.data,
                    model: payload.model,
                    schema: newSchema
                  },
                  { ...state, ...payload, lastUpdate: now, initialize: false }
                ),
                lastUpdate: now
              })),
            state.schema
          )
        }
      };
    },

    [EMIT_EXECUTE_TRIGGER]: state => state,
    [EMIT_COMPONENT_TRIGGER]: state => state,

    [EMIT_COMPONENT_FOCUS]: (state, { payload }) => ({
      ...state,
      initialize: false,
      lastUpdate: Date.now(),
      activeElement: payload.schemaPath
    }),

    /**
     * Creates another instance of a list element
     * @param {Object} payload – the List element
     */
    [EMIT_INSTANCE_ADD]: (state, { payload }) => {
      const now = Date.now();
      // First resolve each path containing the updating path
      const updatePaths = [payload.schemaPath].concat(state.dependencies[payload.schemaPath] || []);
      const updates = [
        {
          ...omit(payload, 'children'),
          length: (payload.length || 0) + 1,
          value: (payload.value || new Array(payload.length)).concat({}),
          lastUpdate: now
        }
      ];

      if (state.links[payload.schemaPath]) {
        state.links[payload.schemaPath].map($id => get(state.schema, $id))
          .forEach(linkedGroup =>
            updates.push({
              ...linkedGroup,
              lastUpdate: now,
              length: updates[0].length,
              value: (linkedGroup.value || new Array(linkedGroup.length)).concat({})
            })
          );
      }

      const newModel = updates.reduce(
        (model, update) => set(model, update.$id, update.value),
        state.model // eslint-disable-line
      );

      const newSchema = updatePaths.reduce(
        (sch, path) =>
          update(sch, path, cmp => ({
            ...resolveSchemaState(
              // If the dependent path was the active group, or a linked group, use latest schema
              updates.find(p => p === path) || cmp,
              {
                ...state,
                schema: sch,
                lastUpdate: now,
                model: newModel,
                initialize: updates.includes(path)
              }
            ),
            update: true,
            lastUpdate: now,
            initialize: !!updates.find(p => p === path)
          })),
        state.schema
      );

      return {
        ...state,
        updatedPaths: updatePaths,
        initialize: false,
        lastUpdate: now,
        errors: {},
        schema: updates.reduce(
          (newSchema, updated) =>
            update(newSchema, updated.schemaPath, () =>
              resolveSchemaState(updated, {
                ...state,
                // newModel is used here, but the actual update process will create new instance values
                model: newModel,
                schema: newSchema,
                lastUpdate: now,
                initialize: true
              }, true)
            ),
          newSchema
        )
      };
    },
    /**
     * Removes an instance of a list element
     * @param {Object} payload – The instance to be removed
     */
    [EMIT_INSTANCE_REMOVE]: (state, { payload: instance }) => {
      const now = Date.now();
      const group = get(state.schema, instance['../'].schemaPath);
      // First resolve each path containing the updating path
      const updatePaths = [group.schemaPath].concat(state.dependencies[group.schemaPath] || []);

      const value = state.config.get(group.$id, state.model) || [];
      const groupInstances = get(group, 'instances') || [];
      const lastSchemaPath = groupInstances[groupInstances.length - 1].schemaPath;

      const updates = [
        Object.assign(group, {
          lastUpdate: now,
          length: Math.max(0, group.length - 1),
          instances: [
            ...groupInstances.slice(0, instance.index),
            ...groupInstances.slice(instance.index + 1)
          ],
          value: [
            ...value.slice(0, instance.index),
            ...value.slice(instance.index + 1)
          ]
        })
      ];

      if (state.links[group.schemaPath]) {
        updates.push(...state.links[group.schemaPath]
          .map($id => get(state.schema, $id))
          .map(linkedGroup => {
            const linkedGroupInstances = get(linkedGroup, 'instances') || [];
            const linkedGroupValues = get(linkedGroup, 'value') || [];
            return Object.assign(linkedGroup, {
              lastUpdate: now,
              length: updates[0].length,
              instances: [
                ...linkedGroupInstances.slice(0, instance.index),
                ...linkedGroupInstances.slice(instance.index + 1)
              ],
              value: [
                ...linkedGroupValues.slice(0, instance.index),
                ...linkedGroupValues.slice(instance.index + 1)
              ]
            });
          })
        );
      }

      const newModel = updates.reduce(
        (model, update) => set(model, update.$id, update.value),
        state.model // eslint-disable-line
      );

      const newSchema = updatePaths.reduce(
        (updatedSchema, path) =>
          update(updatedSchema, path, cmp => cmp
            ? ({
                ...resolveSchemaState(
                  // If the dependent path was the active group, or a linked group, use latest schema
                  updates.includes(path) ? updates.find(p => p === path) : cmp,
                  {
                    ...state,
                    schema: updatedSchema,
                    lastUpdate: now,
                    model: newModel,
                    initialize: false
                  }
                ),
                update: true,
                lastUpdate: now,
                initialize: false
              })
            : undefined // if not found, it was the removed instance
          ),
        state.schema
      );

      const nextState = {
        ...state,
        updatedPaths: updatePaths,
        errors: {},
        lastUpdate: now,
        initialize: false,
        // Remove the dependencies of the LAST instance (unless the instance removed
        // was the last one)
        dependencies: Object.keys(state.dependencies)
          .reduce((deps, k) => {
            if (k === lastSchemaPath) return deps;
            return {
              ...deps,
              [k]: state.dependencies[k].filter(p => p !== lastSchemaPath && !p.startsWith(lastSchemaPath))
            }
          }, {}),
        schema: updates.reduce(
          (newSchema, updated) =>
            update(newSchema, updated.schemaPath, elSchema =>
              resolveSchemaState(Object.assign(elSchema, updated), {
                ...state,
                schema: newSchema,
                model: newModel,
                lastUpdate: now,
                initialize: true
              }, true)
            ),
          newSchema
        ),
        model: newModel
      };
      return nextState;
    },

    [EMIT_VALIDATIONS_PASS]: (state, { payload: { schema, errors, ts } }) =>
      updateSchemaInstance({
        ...state,
        lastUpdate: ts,
        schema: !schema.schemaPath
          ? schema
          : update(state.schema, schema.schemaPath, prevState => ({
              ...prevState,
              ...schema
            })),
        errors,
        initialize: true
      }),

    [EMIT_VALIDATIONS_FAIL]: (state, { payload: { schema, errors, ts } }) =>
      updateSchemaInstance({
        ...state,
        lastUpdate: ts,
        schema: !schema.schemaPath
          ? schema
          : update(state.schema, schema.schemaPath, prevState => ({
              ...prevState,
              ...schema
            })),
        errors,
        initialize: true
      }),

    [EMIT_UPDATE_COMPONENT_STATE]: (state, { payload }) => {
      const now = Date.now();
      const newModel = payload.$id ? set(state.model, payload.$id, payload.value) : state.model;
      // Update the component first
      const newSchema = set(
        state.schema,
        payload.schemaPath,
        resolveSchemaState(
          Object.assign(
            get(state.schema, payload.schemaPath, {}),
            payload,
            { lastUpdate: now, model: newModel }
          ),
          Object.assign(state, {
            lastUpdate: now,
            model: newModel,
            initialize: true
          }),
          true // recursive update component
        )
      );
      // Resolve the dependent paths on the updated schema
      const updatePaths = [].concat(state.dependencies[payload.schemaPath] || []);

      return {
        ...state,
        updatedPaths: updatePaths,
        lastUpdate: now,
        initialize: false,
        model: newModel,
        schema: {
          ...updatePaths.reduce(
            (newSchema, path) =>
              update(newSchema, path, component => component
                ? ({
                    ...resolveSchemaState(component, Object.assign(state, {
                      lastUpdate: now,
                      initialize: true,
                      schema: newSchema,
                      model: newModel
                    }))
                  })
                : component
            ),
            newSchema
          )
        }
      };
    },

    [EMIT_INPUT_UPDATE]: (state, { payload }) => {
      const now = Date.now();
      const newModel = update(
        state.model,
        payload.$id,
        (oldValue) => payload.value === '' ? null : payload.value //eslint-disable-line
      );
      const updatePaths = [payload.schemaPath]
        .concat(state.dependencies[payload.schemaPath] || [])
        .concat(state.dependencies.model || []); // grabbing generic model-bindings
      return {
        ...state,
        updatedPaths: updatePaths,
        model: newModel,
        lastUpdate: now,
        initialize: false,
        schema: {
          ...state.schema,
          ...updatePaths.reduce(
            (nextSchema, path) =>
              set(
                nextSchema,
                path,
                resolveSchemaState({
                  ...get(state.schema, path),
                  ...(payload.schemaPath === path ? omit(payload, 'children') : {})
                }, {
                  ...state,
                  initialize: false,
                  model: newModel,
                  lastUpdate: now,
                  schema: nextSchema
                }
              )
            ),
            state.schema
          )
        }
      };
    }
  },
  initialState
);
