/**
 * Utilities for CSE referencedProperty queries
 * @module utils/references
 */

import CircularJSON from 'circular-json';

import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import isPlainObject from 'lodash/isPlainObject';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import isNil from 'lodash/isNil';
import { schemaValue, resolveRelativePath } from './index';

const NIL = Symbol('NIL');
const hasValue = v => v !== NIL;

/**
 * Debugger
 * @function debug
 * @param {string} prefix the prefix
 * @param {any}  field   The field
 * @param {Object}  query   The query
 * @param {any}  output  The output
 */
const debug = (prefix = '', field = null, query, output, vars) => {
  /* eslint-disable */
  // Node has no `console.group` API
  try {
    console.groupCollapsed(`[QUERY] ${prefix} - ${output}`);
  } catch (e) {
    console.log(`[QUERY] ${prefix} - ${output}`);
  }
  if (prefix) console.log(`Relative Path: ${prefix}`);
  console.log('Vars:');
  console.log(vars);
  console.log('Query:');
  console.log(query);
  console.log('Field:');
  console.log(JSON.parse(CircularJSON.stringify(field)));
  console.log('Result:');
  console.log(output);
  // Node has no `console.group` API
  try {
    console.groupEnd();
  } catch (e) {
    console.log('------------------------------------');
  }
  /* eslint-enable */
};

/**
 * Runs a `query` on the specified `field`. This method gets most of its
 * benefits from recursive calls on a given `field`.
 * @function runQuery
 * @param  {Object} query A mongo-like query to be run on `field`
 * @param  {Object} field The field to run/resolve `query` on
 * @param  {String} prefix The prefix for paths in `query`
 * @param  {Object} vars variables created during query
 *
 * @return {Mixed} The specified queries resolved value
 *
 * @example
 *
 * runQuery(
 *  { $get: 'users[0]' },
 *  { users: [{ name: 'Jon'}] }
 * ) // => { name: 'Jon' }
 */
export const runQuery = (query, field = null, prefix = '', vars = {}) => {
  let output;

  if (!isObject(query)) {
    // String literal as query is either a value on the protoDocument
    // or a literal string (to be returned as the result)
    return get(field, `${prefix}.${query}`, query);
  }

  const reduceOr = (v, q) => (hasValue(v) && v) || runQuery(q, field, prefix, vars) || NIL;
  const reduceAnd = (v, q) => (hasValue(v) && v && runQuery(q, field, prefix, vars)) || NIL;
  // For now using this for BW-compat (if/elseIf wouldn't return false $set's correctly)
  const reduceAndLast = (v, q, i, queries) => {
    // If this is the LAST query of AND's, return the value, regardless of what it is
    if (hasValue(v) && v && i === queries.length - 1) {
      return runQuery(q, field, prefix, vars);
    }
    return (hasValue(v) && v && runQuery(q, field, prefix, vars)) || NIL;
  };
  const reduceAndCompose = (v, q) => (hasValue(v) && v ? runQuery(q, v, prefix, vars) : NIL);

  const {
    /**
     * @type {QueryCommand}
     * @name $if
     * @example
     * {
     *  $if: [
     *    { $gt: 1 } // greater than 1
     *    { $lt: 4 } // less than 4
     *    { $get: 'pathToReturnValue' }
     *  ]
     * }
     */
    $if,
    /**
     * @type {QueryCommand}
     * @name $elseIf
     * @example
     * {
      *  $if: [...],
      *  $elseIf: [
      *    { $gt: 1 } // greater than 1
      *    { $lt: 4 } // less than 4
      *    { $get: 'pathToReturnValue' }
      *  ]
      * }
      */
    $elseIf,
    /**
     * @type {QueryCommand}
     * @name $else
     * @example
     * {
      *  $if: [...],
      *  $elseIf: [...]
      *  $else: [
      *    { $gt: 1 } // greater than 1
      *    { $lt: 4 } // less than 4
      *    { $get: 'pathToReturnValue' }
      *  ]
      * }
      */
    $else,
    /**
     * @type {QueryCommand}
     * @name $and
     * @example
     * {
     *  $and: [{ $gt: 1 }, ...conditions] // returns true if ALL conditions return truthy
     * }
     */
    $and,
    /**
     * @type {QueryCommand}
     * @name $not
     * @example
     * {
     *  $not: { $gt: 1 } // TRUE if condition is falsy
     * }
     */
    $not,
    /**
     * @type {QueryCommand}
     * @name $or
     * @example
     * {
     *  $or: [{ $gt: 1 }, ...conditions] // TRUE if ANY condition returns truthy
     * }
     */
    $or,
    /**
     * @type {QueryCommand}
     * @name $lt
     * @example
     * {
     *  $lt: 5 // TRUE if the field value is < 5
     * }
     */
    $lt,
    /**
     * @type {QueryCommand}
     * @name $gt
     * @example
     * {
    *  $gt: 5 // TRUE if the field value is > 5
    * }
    */
    $gt,
    /**
     * @type {QueryCommand}
     * @name $eq
     * @example
     * {
     *  $eq: 5 // TRUE if the field value is == 5
     * }
     */
    $eq,
    /**
     * @type {QueryCommand}
     * @name $ne
     * @example
     * {
     *  $ne: 5 // TRUE if the field value != 5
     * }
     */
    $ne,
    /**
     * @type {QueryCommand}
     * @name $nil
     * @example
     * {
     *  $nil: 'path' // TRUE if value at 'path' != undefined AND != null
     * }
     */
    $nil,
    /**
     * @type {QueryCommand}
     * @name $has
     * @example
     * {
     *  $has: { $eq: 1 } // TRUE if array/object has a value == 1
     * }
     */
    $has,
    /**
   * @type {QueryCommand}
   * @name $each
   * @description
   * Runs a query on _every_ element within a list of objects/values
   * @example
   * {
    *  $each: { $eq: 1 } // TRUE if array only contains values === `1`
    * }
    */
    $each,
    /**
   * @type {QueryCommand}
   * @name $contains
   * @description
   * Returns `true` if list/object returns true for given query
   * @example
   * {
    *  $contains: { $eq: 1 } // TRUE if array/object has _at least_ one value === `1`
    * }
    */
    $contains,
    /**
   * @type {QueryCommand}
   * @name $compose
   * @description
   * Composes two or more queries, piping the result of each query to the next
   * @example
   * {
    *  $compose: [
    *     { $get: 'value' },
    *     { $gt: 1 },
    *     { $not: 2 }
    *  ] // returns `true` if `value > 1 && value !== 2`
    * }
    */
    $compose,
    /**
   * @type {QueryCommand}
   * @name $var
   * @description
   * Set/get a `var` in the query scope
   * @example
   *  {
    *   $var: ['nameOfVar', { $get: 'value' }], // set's `nameOfVar` to value at `value`
    *   $if: [
    *     { nameOfVar: { $eq: 1 } }
    *   ] // returns `true` if `value === 1`
    * }
    */
    $var,
    /**
   * @type {QueryCommand}
   * @name $get
   * @description
   * Get a value at the given path
   * @example
   * {
    *  $get: 'first_name' // get the value at `first_name`
    * }
    */
    $get,
    /**
   * @type {QueryCommand}
   * @name $set
   * @description
   * Set a value to result of query, or literal value
   * @example
   * {
    *  $set: 1 // set's the output to `1`
    * }
    */
    $set,
    /**
   * @type {QueryCommand}
   * @name $find
   * @description
   * Run a query on object/value in list, returns `true` if any value returns truthy with query
   * @example
   * {
    *  $find: { $eq: 1 } // TRUE if array has _at least_ one value `=== 1`
    * }
    */
    $find,
    $join,
    /**
   * @type {QueryCommand}
   * @name $filter
   * @description
   * Filter a list with query and return value
   * @example
   * {
    *  $filter: { $gt: 1 } // filters array of numbers to values `> 1`
    * }
    */
    $filter,
    /**
   * @type {QueryCommand}
   * @name $onKey
   * @description
   * Iterate over the keys of an object
   * @example
   * {
    *  $onKey: { $eq: 'first_name' } // TRUE if object has property `first_name`
    * }
    */
    $onKey,
    /**
   * @type {QueryCommand}
   * @name $map
   * @description
   * Map a query result to another list of values
   * @example
   * {
    *  $map: { $get: 'first_name' } // Returns a list of all `first_name` values
    * }
    */
    $map,
    $with,
    /**
   * @type {QueryCommand}
   * @name $sum
   * @description
   * Sum a list of numeric values
   * @example
   * {
    *  $sum: { $get: 'list_of_numbers' } // returns the summation of all numbers in value at `list_of_numbers`
    * }
    */
    $sum,
    /**
   * @type {QueryCommand}
   * @name $count
   * @description
   * Returns the length of a list/value
   * @example
   * {
    *  $count: { $get: 'people' } // returns `length` of list/object at `people`
    * }
    */
    $count,
    /**
   * @type {QueryCommand}
   * @name $concat
   * @description
   * Concatenate query results/literals together in a string
   * @example
   * {
    *  $concat: [{ $get: 'first_name' }, ' ', { $get: 'last_name' }]
    * } // returns a string of `first_name` and `last_name` separated by a space
    */
    $concat,
    /**
   * @type {QueryCommand}
   * @name $regex
   * @description
   * Use a regular expression to match values, similar to `$eq`
   * @example
   * {
    *  'first_name': {
    *     $eq: {
    *       $regex: '/jon/i'
    *     }
    *   }
    * } // returns `true` if the `first_name` value matches the rege `/jon/i`
    */
    $regex,
    /**
   * @type {QueryCommand}
   * @name $exists
   * @description
   * Validates whether query had valid result
   * @example
   * {
    *  $exists: { $get: 'person' } // TRUE if `person` key exists
    * }
    */
    $exists,
    $debug,
    $debugger,
    $date,
    /**
   * @type {QueryCommand}
   * @name $find
   * @description
   * Return last value in list
   * @example
   * {
    *  $last: 'users' // returns the last user in `users` list
    * }
    */
    $last,
    // field paths (eg 'person', 'age', etc)
    ...path
  } = query;

  if ($var !== undefined) {
    // SETTING value
    if (isArray($var)) {
      vars[$var[0]] = isPlainObject($var[1])
        ? runQuery($var[1], field, prefix, vars)
        : $var[1];
      if ($debug) console.log(`Setting variable: ${$var[0]} with value ${$var[1]}`);
      if ($debugger) debugger;
    } else {
      // GETTING value
      if ($debug) console.log(`Retrieved variable: ${$var} with value ${vars[$var]}`);
      if ($debugger) debugger;
      return vars[$var];
    }
  }

  if ($if) {
    // Try `if` statement first
    output = [].concat($if).reduce(reduceAndLast, true);
    // If `if` did NOT pass, and `elseIf` was defined, evaluate it
    if ((output === NIL || output === false) && $elseIf) {
      output = [].concat($elseIf).reduce(reduceAndLast, true);
    }
    // If `if` (and `elseIf`, if defined) did NOT pass, and `else` was defined
    if ((output === NIL || output === false) && $else) {
      output = [].concat($else).reduce(reduceAndLast, true);
    }

    if (output === NIL) output = undefined; // we're ABSOLUTELY certain the query was false
    if ($debug) debug(prefix, field, query, output, vars);
    if ($debugger) debugger;
    return isNil(output) ? null : output;
  }

  if ($sum) {
    const add = (v, q) => v + runQuery(q, field, prefix, vars);
    output = [].concat($sum).reduce(add, 0);
    if ($debug) debug(prefix, field, query, output, vars);
    if ($debugger) debugger;
    return isNil(output) ? null : output;
  }

  if ($last) {
    output = !isPlainObject($last) ? get(field, $last) || [] : runQuery($last, field, prefix, vars);
    output = output.length ? output[output.length - 1] : null;
    if ($debug) debug(prefix, field, query, output, vars);
    if ($debugger) debugger;
    return isNil(output) ? null : output;
  }

  if ($concat) {
    const concatStr = (v, q) => `${v}${runQuery(q, field, prefix, vars) || ''}`;
    output = [].concat($concat).reduce(concatStr, '');
    if ($debug) debug(prefix, field, query, output, vars);
    if ($debugger) debugger;
    return isNil(output) ? null : output;
  }

  if ($and) {
    output = [].concat($and).reduce(reduceAnd, true);
    if (output === NIL) output = false;
    if ($debug) debug(prefix, field, query, output, vars);
    if ($debugger) debugger;
    return isNil(output) ? null : output;
  }

  if ($or) {
    output = [].concat($or).reduce(reduceOr, false);
    if (output === NIL) output = false;
    if ($debug) debug(prefix, field, query, output, vars);
    if ($debugger) debugger;
    return isNil(output) ? null : output;
  }

  if ($nil !== undefined) {
    output = isPlainObject($nil)
      ? runQuery($nil, field, prefix, vars)
      : get(field, $nil, null);

    output = isNil(output);

    if ($debug) debug(prefix, field, query, output, vars);
    if ($debugger) debugger;
    return output;
  }

  if ($not !== undefined) {
    output = !runQuery($not, field, prefix, vars);
    if ($debug) debug(prefix, field, query, output, vars);
    if ($debugger) debugger;
    return isNil(output) ? null : output;
  }

  if ($eq !== undefined) {
    output = isArray($eq)
      // Concatting a `null` for 3 iterations, where the last is the comparison
      // outputting a single boolean
      ? $eq.concat(null).reduce(([r1, r2], q, i) => {
        if (i === 0) return [runQuery(q, field, output, vars), r2];
        if (i === 1) return [r1, runQuery(q, field, output, vars)];
        return r1 == r2; // eslint-disable-line
      }, [])
      : !isPlainObject($eq)
        ? field === $eq
        : field === runQuery($eq, field, output, vars);

    if ($debug) debug(prefix, field, query, output, vars);
    if ($debugger) debugger;
    return isNil(output) ? null : output;
  }

  if ($ne !== undefined) {
    // $ne can be an ARRAY of TWO queries and compare the result of each result
    output = isArray($ne)
      // Concatting a `null` for 3 iterations, where the last is the comparison
      // outputting a single boolean
      ? $ne.concat(null).reduce(([r1, r2], q, i) => {
        if (i === 0) return [runQuery(q, field, output, vars), r2];
        if (i === 1) return [r1, runQuery(q, field, output, vars)];
        return r1 != r2; // eslint-disable-line
      }, [])
      : !isPlainObject($ne)
        ? field !== $ne
        : field !== runQuery($ne, field, output, vars);

    if ($debug) debug(prefix, field, query, output, vars);
    if ($debugger) debugger;
    return isNil(output) ? null : output;
  }

  if ($lt !== undefined) {
    output = !isPlainObject
      ? isString(field) ? field.length < $lt : field < $lt
      : field < runQuery($lt, field, output, vars);
    if ($debug) debug(prefix, field, query, output, vars);
    if ($debugger) debugger;
    return isNil(output) ? null : output;
  }

  if ($gt !== undefined) {
    output = !isPlainObject
      ? isString(field) ? field.length > $gt : field > $gt
      : field > runQuery($gt, field, output, vars);
    if ($debug) debug(prefix, field, query, output, vars);
    if ($debugger) debugger;
    return isNil(output) ? null : output;
  }

  if ($compose) {
    output = [].concat($compose).reduce(reduceAndCompose, field);
    if (output === NIL) output = false;
    if ($debug) debug(prefix, field, query, output, vars);
    if ($debugger) debugger;
    return isNil(output) ? null : output;
  }

  if ($each) {
    output = field.map(v => !!runQuery($each, v, prefix, vars)).reduce(v => v || false);
    if ($debug) debug(prefix, field, query, output, vars);
    if ($debugger) debugger;
    return isNil(output) ? null : output;
  }

  if ($has) {
    output = isObject($has) ? !!runQuery($has, field, prefix, vars) : $has in field;
    if ($debug) debug(prefix, field, query, output, vars);
    if ($debugger) debugger;
    return isNil(output) ? null : output;
  }

  if ($contains) {
    const isQuery = isObject($contains);
    output = reduce(
      $onKey ? Object.keys(field || {}) : Object.values(field || {}),
      (matched, f) => {
        if (isString(field) && field.includes($contains)) return true;
        if (matched) return true;
        return isQuery
          ? runQuery($contains, f, prefix, vars)
          : (f || '').toString().indexOf($contains) >= 0;
      },
      false
    );

    if ($debug) debug(prefix, field, query, output, vars);
    if ($debugger) debugger;
    return isNil(output) ? null : output;
  }

  if ($get) {
    let path = $get;
    if (isObject($get)) path = runQuery($get, field, prefix, vars);
    else if (isString($get) && prefix) path = resolveRelativePath(prefix, $get);
    // try the path as relative, then absolute, then null if neither found
    output = isString(path)
      ? get(field, path, schemaValue(field, $get))
      : null;
    if ($debug) debug(prefix, field, query, output, vars);
    if ($debugger) debugger;
    return isNil(output) ? null : output;
  }

  if ($set !== undefined) {
    if (isPlainObject($set)) {
      output = runQuery($set, field, prefix, vars);
    } else {
      output = $set;
    }
    if ($debug) debug(prefix, field, query, output, vars);
    if ($debugger) debugger;
    return isNil(output) ? null : output;
  }

  if ($join) {
    output = isArray(field)
      ? field
        .map(
          v =>
            isPlainObject(isArray($join) ? $join[0] : $join)
              ? runQuery(isArray($join) ? $join[0] : $join, v, prefix, vars)
              : v
        )
        .join(isArray($join) ? $join[1] : $join)
      : runQuery(isArray($join) ? $join[0] : $join, field, prefix, vars);

    if (isArray(output)) output.join(isArray($join) ? $join[1] : $join);
    if ($debug) debug(prefix, field, query, output, vars);
    if ($debugger) debugger;
    return isNil(output) ? null : output;
  }

  if ($count) {
    output = isObject($count)
      ? runQuery($count, field, prefix, vars)
      : field;
    if (isObject(output)) output = Object.keys(output);
    if ($debug) debug(prefix, field, query, output.length, vars);
    if ($debugger) debugger;
    return isNil(output.length) ? null : output.length;
  }

  if ($with) {
    output = []
      .concat($with)
      .reduce(
        (out, val) =>
          isObject(val) ? runQuery(val, field, prefix, vars) : { [val]: get(field, val, null) },
        {}
      );

    if ($debug) debug(prefix, field, query, output, vars);
    if ($debugger) debugger;
    return isNil(output) ? null : output;
  }

  if ($map) {
    output = map(
      field,
      (val, key) => (isObject($map) ? runQuery($map, $map.$onKey ? key : val, prefix, vars) : $map)
    );

    if ($debug) debug(prefix, field, query, output, vars);
    if ($debugger) debugger;
    return isNil(output) ? null : output;
  }

  if ($find) {
    output = Object.keys(field).find(k =>
      runQuery(query, $find.$onKey ? k : get(field, k, null), prefix, vars)
    );
    if ($debug) debug(prefix, field, query, output, vars);
    if ($debugger) debugger;
    return isNil(output) ? null : output;
  }

  if ($filter) {
    if (isArray(field)) {
      output = field.filter((val, key) =>
        runQuery($filter, $filter.$onKey ? key : val, prefix, vars)
      );
    } else if (isObject(field)) {
      output = Object.keys(field).reduce(
        (filtered, k) => ({
          ...filtered,
          ...(runQuery($filter, $filter.$onKey ? k : get(field, k, null), prefix, vars)
            ? { [k]: get(field, k, null) }
            : {})
        }),
        {}
      );
    } else {
      output = runQuery($filter, field, prefix, vars);
    }

    if ($debug) debug(prefix, field, query, output, vars);
    if ($debugger) debugger;
    return isNil(output) ? null : output;
  }

  if ($regex) {
    const [pattern, flags] = $regex.substr(1).split('/');
    output = new RegExp(pattern, flags).test(field);
    if ($debug) debug(prefix, field, query, output, vars);
    if ($debugger) debugger;
    return isNil(output) ? null : output;
  }

  if ($exists !== undefined) {
    output = (field !== undefined && $exists) || (field === undefined && !$exists);
    if ($debug) debug(prefix, field, query, output, vars);
    if ($debugger) debugger;
    return isNil(output) ? null : output;
  }

  if ($date) {
    const dateString = isObject($date) ? runQuery($date, field, prefix, vars) : field;
    output = dateString;
    if ($debugger) debugger;
    return isNil(output) ? null : output;
  }

  if (path) {
    return reduce(
      path,
      (res, val, key) => {
        const rootPath = prefix;
        // compensate for any KEY (i.e. implicit `$get`) lookups with relative
        // paths (these should only be used when necessary)
        const k = key.indexOf('../') === 0
          ? resolveRelativePath(prefix, key)
          : `${rootPath ? `${rootPath}.` : ''}${key}`;

        let f = get(field, k, get(field, key, undefined));
        // If field is `undefined` try resolving as schema value (eg `../../value.test.path`)
        if (f === undefined) f = schemaValue(field, key);
        if (f === undefined) f = schemaValue(vars, key);
        const result = runQuery(val, isNil(f) ? null : f, prefix, vars);
        if ($debug) debug(prefix, field, query, result, vars);
        if ($debugger) debugger;
        return result;
      },
      field
    );
  }

  if ($debugger) debugger;
  return isNil(output) ? null : output;
};

/**
 * @ignore
 */
export default runQuery;
