import React from 'react';

import styles from './styles.scss';

const SmartButton = (props) => {
  const { $id, label } = props;
  return (
    <button
      data-id={$id}
      className={styles.root}
      style={{...props.styles, ...props.layout}}
      onClick={props.onValidate}
    >
      {label}
    </button>
  );
};

export default SmartButton;
