import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.scss';

const Page = ({children, schemaPath, onClick, active}) => {
  const className = classNames({
    [styles.active]: __DEV__ && active,
    [styles.root]: true
  });
  return (
    <div
      className={className}
      onClick={onClick}
      data-schema-path={schemaPath}
    >
      {children}
    </div>
  );
};

Page.propTypes = {
  children: PropTypes.node,
  schemaPath: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool
};

export default Page;
