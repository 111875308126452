import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.scss';

const Section  = (props) => {
    const { title, layout, schemaPath, onClick } = props;
    const className = classNames({
      [styles.active]: __DEV__ && props.active,
      [styles.root]: true
    });
    return (
      <div
        style={layout}
        className={className}
        onClick={onClick}
        data-schema-path={schemaPath}
      >
        {title ? <h1 className={styles.title}>{title}</h1> : null}
        <section style={props.styles} className={styles.container}>
          {props.children}
        </section>
      </div>
    );
};

Section.propTypes = {
  title: PropTypes.string,
  schemaPath: PropTypes.string,
  elements: PropTypes.array,
  location: PropTypes.object,
  onNavigateTo: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.any,
  active: PropTypes.bool
};

export default Section;