import Checkbox from './Checkbox';
import CheckboxGroup from './CheckboxGroup';
import DateInput from './DateInput';
import FileInput from './FileInput';
import InputGroup from './InputGroup';
import LabelValue from './LabelValue';
import Radio from './Radio';
import RadioGroup from './RadioGroup';
import Select from './Select';
import PlainText from './PlainText';
import TextArea from './TextArea';
import TextInput from './TextInput';
import SmartButton from './SmartButton';
import Page from './Page';
import Tab from './Tab';
import Section from './Section';

export default {
  Page,
  Tab,
  Section,
  Checkbox,
  CheckboxGroup,
  DateInput,
  FileInput,
  LabelValue,
  Radio,
  RadioGroup,
  Select,
  TextArea,
  TextInput,
  InputGroup,
  PlainText,
  SmartButton,
  Group: InputGroup,
  Button: SmartButton
};
