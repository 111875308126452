/**
 * Reducer utilities for transitioning CSE's internal state
 * @module utils/reducer
 */

import merge from 'lodash/merge';
import mapValues from 'lodash/mapValues';
import identity from 'lodash/identity';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import get from 'lodash/get';
import omit from 'lodash/omit';
import set from 'lodash/set';
import range from 'lodash/range';
import cloneDeep from 'lodash/cloneDeep';
import update from 'lodash/update';
import isPlainObject from 'lodash/isPlainObject';
import { parseTemplateString, resolveRelativePath } from './index';
import runQuery from './references'; // eslint-disable-line
import validators from '../utils/validation';

/**
 * InputGroups (ie. repeating nodes) create `instances` which do not have identical
 * properties as defined in the schema.
 * @constant {Array<String>}
 */
const GROUP_NON_INSTANCE_PROPS = [
  'length',
  'elements',
  'instances',
  'value',
  'update',
  'layout',
  '$link',
  'visible',
  'wasVisible',
  'update'
];

/**
 * InputGroup's should not copy the following referencedProperty queries to their
 * instances
 * @constant {Array<String>}
 */
const GROUP_NON_INSTANCE_REF_PROPS = [
  'length',
  'layout.display',
  'remove'
];

/**
 * Determins if the node is an element
 * @function hasElements
 * @param {Object} el - A node within a schema
 */
const hasElements = el => 'elements' in el;
/**
 * Determines if the node is an element
 * @function isGroup
 * @param {Object} el - A node within the schema
 */
const isGroup = el => hasElements(el) && (/^(Group|InputGroup)$/.test(el.component) || 'length' in el);

/**
 * Executes the given query. The following cases are handled as defined:
 * - `{ schemaPath: './' }` - Always assumed to be `relative: true`
 * - `{ relative: true, schemaPath: '../' }` - Will resolve to parent component
 * - `{ relative: true, statePath: './' }` - Will always resolve to the element defining the query (expected)
 * - `{ relative: true, statePath: '../' }` - **WARNING**: This will be TWO levels up (can be fixed if queries are updated)
 * - `{ relative: true }` - **WARNING**: Defaults to PARENT component
 *
 * @function executeQuery
 *
 * @param      {Object}            query             A schema query
 * @param      {Boolean}           query.relative    The relative
 * @param      {String}            query.schemaPath  The schema path
 * @param      {Object}            query.query       The query
 * @param      {string}            elementSchema     The element schema
 * @param      {Object}            state             The schema instance's state
 * @return     {Mixed}             Result of query
 */
export const executeQuery = ({ relative, schemaPath, ...query }, elementSchema, state) => {
  if (query === undefined)
    return console.error(`The element with $id ${elementSchema.$id} has an invalid query`);
  // relative $id (model) queries always start from parent-level (not the field value)
  // relative schema queries always start ON the element
  const $id = query[state.config.index.key];
  let queryPath = schemaPath || $id;

  // This logic attempts to expand the initial query path for any `relative`
  // queries.
  // ---------------------------------------------------------------------------
  // If the schema path is "this element", the query will first resolve the `field`
  // (within `utils/references`) to the active element as it exists in the schema
  if (schemaPath === './') queryPath = `schema.${elementSchema.schemaPath}`;
  // any schema path that's not `./` with a `relative: true` will first expand
  // and relative segments (ie. `../`, or `./`)
  else if (relative && schemaPath) queryPath = `schema.${resolveRelativePath(elementSchema.schemaPath, queryPath)}`;
  // An `$id` query (aliased to `statePath` in Trilogy) just resolves to the element's model value
  else if (relative && $id === './') queryPath = `model.${elementSchema.$id}`;
  // This expands a relative model path
  else if (relative && $id) queryPath = `model.${resolveRelativePath(elementSchema.relativePath, queryPath)}`;
  // if no `$id` or `schemaPath` is defined, the `field` will resolve to the RELATIVE
  // path (ie. the closes parent with a model-bound value)
  else if (relative) queryPath = `model.${elementSchema.relativePath}`
  // otherwise, we just run the query on the entire model
  // append `.$id` if exists, otherwise just query on `model`
  else queryPath = `model${$id ? `.${$id.replace(/^\//, '')}` : ''}`;

  let result;

  try {
    result = runQuery(
      {
        // internally, we let the query engine (in utils/references) handle the
        // resolution of the correct field from `queryPath`. Think of it like a
        // glorified `lodash.get`
        // ----
        // omit aliased model-path and relative key, so query engine
        // doesn't mistake them for an implicit `$get`
        [queryPath]: omit(query, state.config.index.key, 'relative')
      },
      state,
      '',
      // adding `/` root property to vars for model queries (schema always has it)
      !schemaPath
        ? { '/': state.model, '$this': elementSchema }
        : {}
    );
  } catch (e) {
    console.error(`[QUERY ERROR] ${e.fileName}:${e.lineNumber}`);
    console.log(e.message);
    result = null;
  }
  return result;
};

/**
 * Resolves any of the dynamic properties specified in `elementSchema.referencedProperties`
 *
 * @function resolveProperties
 * @param      {Object}  elementSchema  The element schema
 * @param      {Object}  state          The schema instance's state
 *
 * @return     {Object}  An object with any/all properties specified in `referencedProperties`
 *                       with their evaluated values
 */
export const resolveProperties = (elementSchema, state) =>
  Object.keys(elementSchema.referencedProperties || {}).reduce((props, resolveTo) => {
    const query = elementSchema.referencedProperties[resolveTo];
    let result = executeQuery(query, elementSchema, state);
    if ('once' in query && result !== null) {
      set(props, 'referencedProperties', omit(props.referencedProperties, resolveTo));
    }
    if (isString(result) && query.schemaPath) result = parseTemplateString(result, props);
    if (result !== get(props, resolveTo)) {
      // Shallow-clone `props` (on new values) to create new reference
      return merge({ ...props }, set({}, resolveTo, result));
    }
    return merge(props, set({}, resolveTo, result));
  }, elementSchema);

/**
 * Creates the paths given an `element` its `$id` (within schema `elements`)
 * and the `parent` or containing Element schema.
 *
 * @function createPaths
 * @param      {Element} element       The element
 * @param      {Number}  elementIndex  The element $id
 * @param      {Element} parent        The parentschema
 * @param      {Object}  state         The schema instance's current state (CSE's active `state`)
 * @param      {String}  elementsKey   The name of the collection `element` belongs to
 *
 * @return     {Object}  The resolved `schemaPath`, `statePath` and `relativePath`
 */
export const createPaths = (element, elementIndex, parent, state, elementsKey = 'elements') => {
  const { indexes } = state;
  const idKey = state.config.index.key;
  const parentId = parent.$id || parent.relativePath || '';
  const id = element[idKey];
  const inherit = state.config.index.inherit && `${id}`.charAt(0) !== '/';
  const pathDelimeter = typeof id === 'number' ? '' : id ? '.' : '';
  const schemaRoot = parent.schemaPath ? `${parent.schemaPath}.` : '';
  const relativeRoot = typeof id === 'number' ? `[${id}]` : id ? `${id}` : '';
  const $id =
    inherit && parentId
      ? `${parentId}${pathDelimeter}${relativeRoot.replace(`${parentId}${pathDelimeter}`, '')}`
      : relativeRoot.replace(/^\//, '');

  const paths = {
    '/': 'schemaPath' in parent ? parent['/'] || parent : element,
    '../': parent || element,
    relativePath: isGroup(parent) ? $id : parentId || parent.relativePath || '',
    schemaPath: elementsKey ? `${schemaRoot}${elementsKey}[${elementIndex}]` : '',
    ...((idKey in element && $id) || hasElements(element) && parentId
      ? { $id: $id || parentId }
      : {}
    )
  };

  if (paths.$id) indexes[paths.schemaPath] = paths.$id;
  return paths;
};

/**
 * Given an `element`, any `validations` are evaluated using the `constraint` and
 * `errorMessage` property.
 *
 * @function updateValidations
 * @param  {Object} element The element's schema
 * @param  {Object} state   The current state
 * @return {Object}         The validations for `element` ({ errors: [] })
 */
export const updateValidations = (element, state = {}) => {
  if (!('validations' in element) || element.pristine === true) return {};
  const errors = Object.keys(element.validations || {})
    .filter(
      type =>
        isPlainObject(element.validations[type].queryConstraint)
          ? !executeQuery(element.validations[type].queryConstraint, element, state)
          : !validators[type].isValid(element.value, element.validations[type])
    )
    .map(key => element.validations[key].errorMessage || '');
  if (state.errors && errors.length) state.errors[element.schemaPath] = errors;
  return { errors };
};

const updateTemplatePaths = (element, pristineRefProps = element.referencedProperties) =>
  pristineRefProps
  ? Object.assign(element, {
      referencedProperties: mapValues(
        pristineRefProps,
        query => mapValues(query, (v, k) =>
          k === 'statePath' || k === 'schemaPath'
            ? parseTemplateString(v, element)
            : v
        )
      )
    })
  : element;

/**
 * Evaluates the given `element` (assumed to be a `Group` definition) by either
 * creating or updating the `instances` of the element. The `instances` are created
 * as components with `component: 'GroupInstance'`, whose `elements` are exactly those
 * of the given `Group` `element`.
 *
 * @function updateInstances
 * @param      {Object}    element     A `Group` definition
 * @param      {Function}  transition  The transition each `instance` should enter
 * @param      {Object}    state       The schema instance's state
 *
 * @return     {Object}    An object with `length` and `instances`, intended to be merged
 *                         with a `Group`
 */
const updateInstances = (parent, transition = identity, state) =>
  isGroup(parent)
    ? {
        instances: range(parent.length).map(i => {
          const instance = (parent.instances && parent.instances[i] || {});

          if (!Object.keys(instance).length) {
            Object.assign(instance, omit(parent, GROUP_NON_INSTANCE_PROPS), {
              ...(parent.elements.length === 1 && 'elements' in parent.elements[0]
                ? cloneDeep(parent.elements[0])
                : {
                  component: `${parent.component || 'Generic'}Instance`,
                  referencedProperties: omit(parent.referencedProperties, GROUP_NON_INSTANCE_REF_PROPS),
                  elements: cloneDeep(parent.elements)
                }
              ),
              index: i,
              order: i + 1,
              [state.config.index.key]: i,
              instance: true,
              last: i === parent.length - 1
            });

            return instance;
          }

          return Object.assign({}, instance, {
            index: i,
            order: i + 1,
            [state.config.index.key]: i,
            instance: true,
            last: i === parent.length - 1
          });
        })
        .map((instance, i) => Object.assign(instance, {
          ...createPaths(instance, i, parent, state, 'instances')
        }))
        .map((instance) => updateTemplatePaths(
          instance,
          // Following same convention as instance-creation (input group with a single element
          // is assumed to be the root instance, when an input group has multiple elements
          // the input group is cloned as the instance root, and all its elements included)
          parent.elements.length === 1
            ? parent.elements[0].referencedProperties
            : omit(parent.referencedProperties, GROUP_NON_INSTANCE_REF_PROPS)
        ))
        .map((instance) => transition({ ...instance, lastUpdate: state.lastUpdate }))
        .filter(el => el.remove !== true)
      }
    : {};

/**
 * Evaluates the given `element.elements` individually, where each `element` is assumed
 * to be an instance of some `Element`.
 *
 * @param      {Object}    element     A `Container` definition (ie has an `elements` property)
 * @param      {Function}  transition  The transition each `element` should enter
 * @param      {Object}    state       The schema instance's state
 * @param      {String}    elementsKey The parent element's key whose value holds its child elements
 *
 * @return     {Object}    An object with `length` and `elements`, intended to be merged with a `Container`
 */
const updateElementGroup = (parent, transition = identity, state, elementsKey = 'elements') =>
  !isGroup(parent) && elementsKey in parent && isArray(parent[elementsKey])
    ? {
        [elementsKey]: parent[elementsKey]
          .filter(el => !!el)
          .map((el, i) => ({
            ...el,
            ...createPaths(el, i, parent, state, elementsKey, elementsKey)
          }))
          .map(el => transition(el))
          .filter(el => el.remove !== true)
      }
    : {};

/**
 * An _entrance transition_, used as the first stage of a schema's reducer cycle
 *
 * @function resolveSchemaState
 * @param      {Object}  element  The element to transition
 * @param      {Object}  state    The schema instance's state
 * @return     {Object}  The transitioned element
 */
export const resolveSchemaState = (element, state, recursiveUpdate = false) => {
  const { $id } = element;
  const parent = element['../'] || {
    wasVisible: true,
    visible: true,
    value: null
  };

  const prevDisplay = get(element, 'layout.display', 'block');
  // An elements `visible` status is resolved, in order, from:
  //  1. `element.visible`
  //  2. `element.layout.display` (where `none` makes `element.visible === false`)
  //  3. The element's parent's visiblity
  const wasVisible = 'visible' in element
    ? element.visible && parent.wasVisible
    : prevDisplay !== 'none' && parent.wasVisible;
  // Save the previous value on element, reassigned if no `referencedProperties.value` override
  const prevValue = get(state.model, $id);
  // Before running referenced props, we create the new state of the element and UPDATE the STATE
  // This is necessary for ANY QUERIES running AGAINST the schema, as if their accessing the state
  // of this element, they need the latest
  const newElement = Object.assign({}, element, {
    wasVisible,
    data: state.data,
    model: state.model,
    active: state.activeElement === element.schemaPath,
    // React (since v0.14) freezes ANY object that's used for `style`
    // see: https://github.com/facebook/react/issues/11520#issuecomment-343731876
    ...('layout' in element ? { layout: cloneDeep(element.layout) } : {}),
    // React (since v0.14) freezes ANY object that's used for `style`
    // // see: https://github.com/facebook/react/issues/11520#issuecomment-343731876
    ...('styles' in element ? { styles: cloneDeep(element.styles) } : {}),
    // On initialize, if a value exists (assumed to be from model), then pristine = false, otherwise
    // pristine is false upon user entry
    pristine: 'pristine' in element ? element.pristine : true,
    // Storing the schema-defined length (if it exists) on the group for cases
    // where the model _becomes_ empty
    ...(isGroup(element) && 'length' in element
      // If the initialLength has already been set, continue to use that
      ? {
          initialLength: element.initialLength || element.length,
          // If the model value isn't present, we fall back to the initialLength (if defined)
          // or use the last `element.length`
          length: get(prevValue, 'length', element.initialLength || element.length)
        }
      : {}
    ),
    value: prevValue
  });
  // Here we update the state
  update(
    state.schema,
    newElement.schemaPath,
    (previousElement = {}) => Object.assign({}, previousElement, newElement)
  );
  // Resolve the referenced properties for `element` to create `newElement`
  Object.assign(newElement, resolveProperties(newElement, state));
  // An element is visible ONLY IF its PARENT is visible AND its styles aren't affecting visibility
  // Set final visibility, which is used by any children and each subsequent state transition
  const isVisible = get(newElement, 'layout.display') !== 'none' && parent.visible;
  // Make any final changes
  Object.assign(newElement, {
    update: true,
    lastUpdate: state.lastUpdate,
    visible: isVisible,
    // When an element is hidden, its pristine value is reset
    pristine: wasVisible && !isVisible ? true : newElement.pristine
  });
  // Register any links that haven't already been registered
  if (
    newElement.$link &&
    (state.links[newElement.$link] || []).indexOf(newElement.schemaPath) < 0
  ) {
    Object.assign(state, {
      links: {
        ...state.links,
        [newElement.$link]: (state.links[newElement.$link] || []).concat(newElement.schemaPath)
      }
    });
  }
  // The model is updated ON CHANGE, but if a refprop changed the value further, we NOW
  // update that value, just before processing any child-elements
  if (newElement.$id) set(state.model, $id, newElement.value);
  // When updating the state, just update the non-referencing values, as the child nodes will still
  // need to update against their original states
  update(
    state.schema,
    newElement.schemaPath,
    (previousElement = {}) => Object.assign({}, previousElement, newElement)
  );
  // Set validations before updating schema, so any queries on this element going
  // forward are as up-to-date as possible
  Object.assign(newElement, updateValidations(newElement, state));
  // Update the global error state for this component
  if (state.errors[newElement.schemaPath] && get(newElement, 'errors.length')) {
    state.errors[newElement.schemaPath] = newElement.errors;
  } else {
    delete state.errors[newElement.schemaPath];
  }

  // The `$link` attribute specifies a model path and updates the length of the linking element
  // this is only run on initialization to make the initial link between the groups
  if (state.initialize && newElement.$link) {
    newElement.length = get(state.schema, `${newElement.$link}.length`, newElement.length);
  }

  if (state.initialize || recursiveUpdate || wasVisible !== isVisible) {
    // Update children/instances
    Object.assign(
      newElement,
      updateElementGroup(
        newElement,
        el => resolveSchemaState(
          el,
          state,
          state.initialize || recursiveUpdate || wasVisible !== isVisible
        ),
        state
      ),
      updateInstances(
        newElement,
        instance => resolveSchemaState(
          instance,
          state,
          state.initialize || recursiveUpdate || wasVisible !== isVisible
        ),
        state,
        state.initialize || recursiveUpdate || wasVisible !== isVisible
      )
    );
  }

  if (newElement.$id && newElement.$debug)
    console.log(`Element at '${newElement.schemaPath}' setting '${$id}' to ${newElement.value}`);
  // If some child-node requires an update, we backpropagate up its ancestry chain
  // notifying each containing node that it contains an updated child node
  if (parent && parent.lastUpdate !== state.lastUpdate) {
    let target = newElement;
    while (target && target['../'] && (target = target['../'])) { // eslint-disable-line
      update(state.schema, target.schemaPath, targetSchema => {
        if (!targetSchema) return targetSchema;
        return Object.assign(targetSchema, {
          lastUpdate: state.lastUpdate,
          ...('$id' in targetSchema ? { value: get(state.model, targetSchema.$id) } : {})
        });
      });
    }
  }

  return newElement;
};

/**
 * Transitions the given `state` against its internal `model` state.
 *
 * @function updateSchemaInstance
 * @param      {Object}  state   The state to transition
 * @return     {Object} The transitioned state
 */
export const updateSchemaInstance = state => Object.assign(state, {
  activeElement: state.activeElement,
  schema: resolveSchemaState(
    Object.assign(
      state.schema,
      createPaths(state.schema, 0, { wasVisible: true, visible: true, update: true }, state, null)
    ),
    state,
    true
  )
});
