exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* COLORS */\n/* DISPLAY */\n.components-SchemaInfo-styles__devPopover--17F9q {\n  background-color: rgba(255, 255, 255, 0.8);\n  display: block;\n  padding: 10px;\n  border: 2px solid;\n  position: fixed;\n  list-style: none;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  transform: translate3d(0, 20px, 0);\n  opacity: 0;\n  height: 30vh;\n  max-height: 30vh;\n  overflow: scroll;\n  transition: all 150ms ease;\n  pointer-events: none;\n  z-index: 10;\n  width: 100vw; }\n  .components-SchemaInfo-styles__devPopover--17F9q strong {\n    user-select: none; }\n  .components-SchemaInfo-styles__devPopover--17F9q.components-SchemaInfo-styles__active--33m7x {\n    opacity: 1;\n    transform: translate3d(0, 0, 0);\n    pointer-events: visibleFill; }\n", ""]);

// exports
exports.locals = {
	"devPopover": "components-SchemaInfo-styles__devPopover--17F9q",
	"active": "components-SchemaInfo-styles__active--33m7x"
};