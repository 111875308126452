exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* COLORS */\n/* DISPLAY */\n.components-Form-CheckboxGroup-styles__root--pbAR7 {\n  align-items: flex-start;\n  flex: 1 0 auto;\n  display: flex;\n  justify-content: space-evenly;\n  width: 100%; }\n\n.components-Form-CheckboxGroup-styles__horizontal--VAn9b {\n  flex-direction: row;\n  flex-wrap: wrap; }\n  .components-Form-CheckboxGroup-styles__horizontal--VAn9b > label {\n    width: 50%; }\n\n.components-Form-CheckboxGroup-styles__vertical--2-HaJ {\n  flex-direction: column;\n  flex-wrap: wrap; }\n", ""]);

// exports
exports.locals = {
	"root": "components-Form-CheckboxGroup-styles__root--pbAR7",
	"horizontal": "components-Form-CheckboxGroup-styles__horizontal--VAn9b",
	"vertical": "components-Form-CheckboxGroup-styles__vertical--2-HaJ"
};