/**
 * Validation utilities
 * @module utils/validation
 */

import moment from 'moment';
import includes from 'lodash/includes';
import reduce from 'lodash/reduce';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import trimEnd from 'lodash/trimEnd';

export const API_DATE_FORMAT = 'YYYY-MM-DD';
export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

const EMPTY_SELECTION = null;
export const DATE_RESTRICTIONS = {
  PAST: {
    yyyy: '1900',
    mm: '01',
    dd: '01'
  },
  FUTURE: {
    yyyy: '2100',
    mm: '12',
    dd: '31'
  }
};

/**
 * @function parseDateString
 * @param {*} dtString - The date string to parse
 */
export const parseDateString = (dtString) => {
  if (moment.isMoment(dtString)) {
    return {
      yyyy: dtString.format('YYYY'),
      mm: dtString.format('MM'),
      dd: dtString.format('DD')
    };
  }
  // Partial/Full date parser used by DateInput component. Now with ISO-8601 string support!
  const matches = dtString.match(/^([0-9]{4})?-?([0-9]{2})?-?([0-9]{2})?/);
  if (matches) {
    const dateParts = matches.map((v) => (isNil(v) ? '' : v));
    return {
      yyyy: dateParts[1],
      mm: dateParts[2],
      dd: dateParts[3]
    };
  }
  return {
    yyyy: '',
    mm: '',
    dd: ''
  };
};

/**
 * @function validfullDate
 * @param {*} value
 */
export const validFullDate = (value) => {
  if (isEmpty(value)) {
    // required constraint will handle if an empty date is allowed
    return true;
  } else if (moment.isMoment(value)) {
    // just in case we pass a moment. because why use static typing
    return value.isValid();
  } else if (includes(value, 'T')) {
    // just in case we pass an ISO string from BE. Note: the case service uses fractional sections, Moment does not.
    return moment.utc(value, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]', true).isValid();
  }
  // Most of our use cases should go here for a 'full' date constraint.
  return moment.utc(value, API_DATE_FORMAT, true).isValid();
};

export const validateDateInput = (value, constraint) => {
  const dateParts = parseDateString(value);
  // Partial dates must have a month if a year is selected, and a day if a month is selected.
  if (constraint === 'partial') {
    const bitWeights = { yyyy: 4, mm: 2, dd: 1 };
    const sum = reduce(
      dateParts,
      (result, val, key) =>
        val !== EMPTY_SELECTION && val.match(/\d+/)
          ? result + bitWeights[key]
          : result,
      0
    );
    if ([0, 4, 6].includes(sum)) {
      return true;
    } else if (sum === 7) {
      return validFullDate(value);
    }
    return false;
  }

  return validFullDate(value);
};

/**
 * Transform date parts to ISO, supporting partial dates
 * @function dateToISOString
 */
export const dateToIsoString = (dateParts) => {
  const { yyyy, mm, dd } = mapValues(dateParts, (val) => (isNil(val) ? '' : val));
  return trimEnd(`${yyyy}-${mm}-${dd}`, '-');
};

/**
 * @function calculateMinDate
 * @param {*} restrictPast
 */
export const calculateMinDate = (restrictPast) =>
  (restrictPast
    ? moment()
    : moment(
      dateToIsoString(DATE_RESTRICTIONS.PAST),
      API_DATE_FORMAT,
      true
    )).startOf('day');

/**
 * Future dates are restricted to now + 24hrs
 * @function calculateMaxDate
 * @param {*} restrictFuture
 */
export const calculateMaxDate = (restrictFuture) =>
  (restrictFuture
    ? moment().add(1, 'day')
    : moment(
      dateToIsoString(DATE_RESTRICTIONS.FUTURE),
      API_DATE_FORMAT,
      true
    )).endOf('day');

const validations = {
  required: {
    isValid: (value, { constraint }) => !constraint || (!isNil(value) && value !== '')
  },

  maxLength: {
    isValid: (value, { constraint }) =>
      isNil(value) || value.length <= constraint
  },

  minLength: {
    isValid: (value, { constraint }) =>
      isNil(value) || value.length >= constraint
  },

  email: {
    isValid: (value) =>
      isNil(value) || EMAIL_REGEX.test(value)
  },

  pattern: {
    isValid: (value, { constraint }) => isNil(value) || new RegExp(constraint).test(value)
  },

  date: {
    isValid: (value, validation = { constraint: 'partial' }) =>
      isNil(value) || validateDateInput(value, validation.constraint)
  },

  dateRestriction: {
    isValid: (value, { constraint }) => {
      // Ignore invalid and partial dates
      const date = moment(value, API_DATE_FORMAT, true);
      if (!date.isValid()) {
        return true;
      }
      const minDate = calculateMinDate(true);
      const maxDate = calculateMaxDate(true);

      if (constraint === 'past') {
        return minDate.isBefore(date);
      } else if (constraint === 'future') {
        return maxDate.isAfter(date);
      } else if (constraint === 'both') {
        return minDate.isBefore(date) && maxDate.isAfter(date);
      }
      return true;
    }
  }
};
/**
 * @ignore
 */
export default validations;
