import React from 'react';
import PropTypes from 'prop-types';

import Radio from '../Radio';
import styles from './styles.scss';

class RadioGroup extends React.Component {
  canUpdate = true;

  shouldComponentUpdate () {
    return this.canUpdate;
  };

  componentWillReceiveProps (nextProps) {
    const { value, options } = nextProps;
    const hasCheckedValue = options.find(
      (option) => option.value === value && option.checked
    );
    if (!value || hasCheckedValue) this.canUpdate = true;
  }

  componentDidUpdate (prevProps) {
    const { options, value } = this.props;
    const hasCheckedValue = options.find(
      (option) => option.value === value && option.checked
    );

    if (!hasCheckedValue && value && value !== prevProps.value) {
      this.canUpdate = false;
      this.handleChange({ target: { value } });
    }
  }

  handleChange = (e) => {
    if ('stopPropagation' in e) e.stopPropagation();
    this.props.onChange({
      ...this.props,
      value: e.target.value,
      options: this.props.options.map((opt) => ({
        ...opt,
        checked: opt.value === e.target.value
      }))
    });
  };

  render () {
    const { $id, schemaPath, options, orientation, layout } = this.props;
    const className = styles[orientation];

    return (
      <div className={`${styles.root} ${className}`} style={layout}>
        {options.map((opt) => (
          <Radio
            key={$id}
            style={this.props.styles}
            name={$id}
            onChange={this.handleChange}
            schemaPath={schemaPath}
            $id={$id}
            {...opt}
          />
          ))}
      </div>
    );
  };
}

RadioGroup.propTypes = {
  options: PropTypes.array,
  $id: PropTypes.string,
  schemaPath: PropTypes.string,
  orientation: PropTypes.string
};

RadioGroup.defaultProps = {
  orientation: 'horizontal'
};

export default RadioGroup;
