exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\n/* COLORS */\n/* DISPLAY */\n.components-Form-Checkbox-styles__root--1nfmz {\n  align-items: center;\n  display: inline-flex;\n  flex: 1 1 auto;\n  cursor: pointer; }\n  .components-Form-Checkbox-styles__root--1nfmz input {\n    -webkit-appearance: none;\n    cursor: pointer;\n    display: inline-block;\n    position: relative;\n    border: 2px solid #00A9E0;\n    margin-right: 10px;\n    height: 20px;\n    width: 20px; }\n    .components-Form-Checkbox-styles__root--1nfmz input:checked {\n      box-shadow: 0 0 0 2px black; }\n    .components-Form-Checkbox-styles__root--1nfmz input::after {\n      display: block;\n      position: absolute;\n      height: 18px;\n      width: 18px; }\n    .components-Form-Checkbox-styles__root--1nfmz input:checked::after {\n      background-color: #00A9E0;\n      line-height: 14px;\n      content: \"\\2714\\FE0E\";\n      color: white;\n      font-size: 10px;\n      text-align: center; }\n", ""]);

// exports
exports.locals = {
	"root": "components-Form-Checkbox-styles__root--1nfmz"
};