import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.scss';

const Tab = (props) =>  {
    const { schemaPath, layout, onClick } = props;
    const className = classNames({
      [styles.active]: __DEV__ && props.active,
      [styles.root]: true
    });
    return (
      <div
        className={className}
        onClick={onClick}
        data-schema-path={schemaPath}
        style={layout}
      >
        {props.children}
      </div>
    );
};

Tab.propTypes = {
  schemaPath: PropTypes.string,
  location: PropTypes.object,
  onNavigateTo: PropTypes.func,
  onChangeValue: PropTypes.func,
  value: PropTypes.any
};

export default Tab;
