exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* COLORS */\n/* DISPLAY */\n.components-Form-Radio-styles__root--3ET4W {\n  align-items: center;\n  cursor: pointer;\n  display: inline-flex;\n  padding: 3px 15px 2px 0; }\n  .components-Form-Radio-styles__root--3ET4W input {\n    -webkit-appearance: none;\n    border-radius: 100%;\n    border: 2px solid #00A9E0;\n    cursor: pointer;\n    height: 18px;\n    margin-right: 5px;\n    width: 18px; }\n    .components-Form-Radio-styles__root--3ET4W input::before {\n      background: none; }\n    .components-Form-Radio-styles__root--3ET4W input:checked {\n      background: #0083ad;\n      box-shadow: inset 0 0 0 3px #FFFFFF; }\n    .components-Form-Radio-styles__root--3ET4W input:focus {\n      border-color: #071D49;\n      outline: none; }\n", ""]);

// exports
exports.locals = {
	"root": "components-Form-Radio-styles__root--3ET4W"
};