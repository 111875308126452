/** @module workers/pool */

import omitBy from 'lodash/omitBy';

import ThreadWorker from './graphThread.worker';
// Used for single nodes being handed to thread (clones don't care about references, like JSON)
const FILTER_NODE = v => typeof v === 'function';
/**
 * Filter and flatten all nodes with queries defined within a schema and "partitions"
 * them amongst `count` lists. Returns a multi-dimensional array whose length is
 * `count` and each entry is a list of schema nodes.
 *
 * @param  {Object}   schema     The schema to partition
 * @param  {Number}   count      The number of partitions
 * @param  {Array}    partitions Used for recursion (by default create's `count` partitions, or `count` arrays)
 * @param  {Number}   i          The index of which partition is currently active
 * @return {Array}               The partitioned schema
 */
const flattenSchema = (schema, flattened = []) => schema
  ? (schema.instances || schema.elements || []).reduce((flat, elementSchema) =>
      flattenSchema(elementSchema, flat),
      flattened.concat(('referencedProperties' in schema || 'validations' in schema || '$link' in schema)
        ? omitBy(schema, FILTER_NODE)
        : []
      )
    )
  : flattened;

/**
 * Mock worker API, since chrome doesn't support subworkers, we do minimal work
 * in this class on the main thread (just merges dependencies into the `existingDependencies`)
 *
 * calls its `onmessage` callback whenever a worker thread has new dependencies
 */
export default class DependencyGraphWorker {
  options = null;
  schema = null;
  graph = null;
  partitions = null;

  constructor(workerCount=1) {
    // Checking to see if we can get total number of cores for base number of threads

    // [Important Note:] As part of short term Performance fix, decresing thread count to 1.  
    const count = workerCount;
   // const count = workerCount || navigator.hardwareConcurrency ? 3 * navigator.hardwareConcurrency : 10;
    this.threads = new Array(count).fill(null);
    this.reset();
  }

  /**
   * Reset the dependency manager. Resets all progress and will discard
   * any in-progress threads (which will subsequently deactivate)
   */
  reset() {
    this.lastUpdate = Date.now();
    this.graph = {}; // the dependency graph
    this.options = {}; // options (configured through `run` request)
    this.partitions = 0;
    this.resolved = 0;
    this.schema = {}; // the current root schema
    this.updateDebugger();
    this.threads = this.threads.map(t => t && t.terminate() || null);
  }

  interrupt() {
    this.reset();
  }

  destroy = () => {
    this.reset();
    if (this.debugEl && this.debugEl.parentNode) {
      this.debugEl.parentNode.removeChild(this.debugEl);
    }
  }

  /**
   * Updates the DOM display of the worker's progress
   */
  updateDebugger() {
    if (!this.options.debug) return;

    if (!this.debugEl) {
      // Create the DOM elements for each display
      this.debugEl = document.createElement('div');
      this.debugEl.style.cssText = `
        background-color: #EFEFEF;
        border: 5px solid rgba(0, 0, 0, 0.2);
        opacity: 0;
        pointer-events: none;
        position: fixed;
        padding: 5px;
        bottom: 20px;
        right: 20px;
        transition: opacity 200ms ease;
        z-index: 9999;
      `;

      this.debugEl.active = document.createElement('span');
      this.debugEl.active.style.display = 'block';
      this.debugEl.progress = document.createElement('span');
      this.debugEl.progress.style.display = 'block';
      this.debugEl.dependencyCount = document.createElement('span');
      this.debugEl.dependencyCount.style.display = 'block';
      this.debugEl.appendChild(this.debugEl.active);
      this.debugEl.appendChild(this.debugEl.progress);
      this.debugEl.appendChild(this.debugEl.dependencyCount);

      document.body.appendChild(this.debugEl);
    }

    // The total completion %
    const completion = Math.round((this.resolved)/(this.partitions || 1) * 100);
    // The total active threads
    const activeThreads = this.threads.filter(t => t && t.active).length;
    // setting the latest values...
    this.debugEl.progress.innerText = `Progress: ${completion}%`;
    this.debugEl.active.innerText = `Active: ${activeThreads}/${this.threads.length}`;
    this.debugEl.dependencyCount.innerText = `Nodes: ${this.partitions}`;
    // This ensures that the transition (for `opacity`) will run correctly
    this.debugEl.offsetTop; // eslint-disable-line
    // 5 seconds after completion, the panel will fade away...
    if (completion >= 100) setTimeout(() => this.debugEl.style.opacity = 0, 5000);
    else this.debugEl.style.opacity = 1;
  }

  /**
   * Create a thread and create message handlers
   *
   * @return {Worker}         The thread instance
   */
  createThread = () => {
    const thread = new ThreadWorker();
    thread.active = true;

    thread.onmessage = ({ data: [type, dependencies, schemaPath, startTime] }) => {
      // The thread is still active if there's more dependencies to be resolved
      thread.active = type !== 'COMPLETE';
      // An update message has the signature [
      //  1. status,
      //  2. resolved dependencies,
      //  3. schemaPath to element whose dependencies were resolved,
      //  4. the time the thread started its job
      // ]
      if(type === 'UPDATE') {
        this.resolved = this.resolved + 1;
        // Assign dependencies to this.graph
        Object.keys(dependencies).forEach(k =>
          Object.assign(this.graph, {
            [k]: (this.graph[k] || []).filter(v => !dependencies[k].includes(v)).concat(dependencies[k])
          })
        );

        this._postMessage(['UPDATE', this.graph, schemaPath, startTime]);
      } else {
        this._postMessage([type, null, null, dependencies]);
      }
    };

    return thread;
  };

  /**
   * Post a message to the `onmessage` listener (in this case, `SchemaUI`)
   *
   * @param  {Object} data - Data passed to initializing class
   */
  _postMessage([,...data]) {
    const [,,,startTime] = data;
    // This response is out-of-date. Throw it out.
    if (startTime < this.lastUpdate) return;
    // Completed must wait for both `i` to exceed partition count, and NO THREADS can be active
    const completed = !this.threads.find(t => t && t.active);
    this.updateDebugger();
    this.onmessage({ data: [completed ? 'COMPLETE' : 'UPDATE', this.graph] });
    if (completed) {
      this.reset();
      this.onComplete();
    }
  }

  /**
   * Implementation of Worker.postMessage, from parent thread to worker thread
   *
   * @param  {Object} data  - Data passed to worker thread
   */
  postMessage(data) {
    if (!Array.isArray(data) || data[0] !== 'CSE') return null;
    const progress = new Promise((resolve) => { this.onComplete = resolve; });
    this.run(data);
    return progress;
  }

  /**
   * Non-worker API, implementation to manage threads and signal SchemaUI of any
   * dependency graph updates.
   *
   * This method continually updates the status of `partitions` (needing dependency resolution)
   * and `threads`.
   *
   * @param  {Object} options.data  - Data passed from SchemaUI to thread management worker
   */
  run(data) {
    const [, schema, indexes, options = {}, existingDependencies = {}, root] = data;
    // ALWAYS update with latest schema (we assume this is the most up-to-date)
    // This is the _fastest_ way to remove any non-thread-safe properties from the entire schema
    this.schema = root || schema;
    this.indexes = indexes;
    this.options = options;
    this.graph = existingDependencies || {};
    this.partitions = 0;
    // update the total dependency count
    const partitions = flattenSchema(schema);
    if (!partitions.length) return this._postMessage(['COMPLETE']);
    this.threads = partitions
      .reduce((partitions, schema, i) => [
        ...partitions.slice(0, i%partitions.length),
        partitions[i%partitions.length].concat(schema),
        ...partitions.slice(i%partitions.length + 1),
      ], new Array(this.threads.length).fill().map(() => []))
      .map(partition => {
        this.partitions += partition.length; // eslint-disable-line
        const thread = this.createThread();
        thread.postMessage([partition, indexes, options, this.schema, Date.now()]);
        return thread;
      });

    return this.threads;
  }
}
